import React, { useEffect, useState } from 'react';

import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function App() {
  const [show, setShow] = useState<boolean>(false);

  const [page, setPage] = useState<string>('');
  const [card, setCard] = useState<string>('');

  const showModal = (event: CustomEvent) => {
    setPage(event.detail?.page || '');
    setCard(event.detail?.card || '');
    setShow(true);
  };

  useEffect(() => {
    document.addEventListener("show-back-call-modal", showModal as EventListener);

    return () => {
      document.removeEventListener("show-back-call-modal", showModal as EventListener);
    };
  }, []);

  const handleSubmit = (values: FormikValues) => {
    fetch('/api/v1/back-call', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setShow(false);
      });
  };

  const initialValues: FormikValues = {
    page,
    card,
    name: '',
    phone: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Укажите ваше имя'),
    phone: Yup.string()
      .required('Укажите номер телефона'),
  });

  return (
    <div className="back-call-modal-form">
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Узнать цену</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
          >
            {formik => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <p>Оставьте заявку и наш менеджер вам перезвонит.</p>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control {...formik.getFieldProps("name")}
                                type="text"
                                placeholder="Имя"
                                isInvalid={!!(formik.touched["name"] && formik.errors["name"])}
                  />
                  <Form.Control.Feedback type="invalid">{typeof formik.errors.name === "string" && formik.errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Control {...formik.getFieldProps("phone")}
                                type="tel"
                                placeholder="+7 (999) 999-99-99"
                                isInvalid={!!(formik.touched["phone"] && formik.errors["phone"])}
                  />
                  <Form.Control.Feedback type="invalid">{typeof formik.errors.phone === "string" && formik.errors.phone}</Form.Control.Feedback>
                </Form.Group>
                <Button className="btn-lg full-width" type="submit" variant="primary">Отправить заявку</Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <p>
            Нажимая кнопку, вы подтверждаете согласие с условиями <a href="/documents/privacy-policy.docx">Политики конфиденциальности</a>.
          </p>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
