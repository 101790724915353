import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('back-call-modal-form') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll(".back-call-button").forEach(
    (item: Element) => item.addEventListener("click",
      (event: Event) => {
        event.preventDefault();
        document.dispatchEvent(new CustomEvent("show-back-call-modal", {
          detail: {
            page: item.getAttribute('data-page'),
            card: item.getAttribute('data-card'),
          }
        }));
      }
    )
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
